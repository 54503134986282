
.amounts .cart-product-name {
    text-transform: uppercase;
}


/* We remove the line below all amounts in recap of checkout page */
.amounts tr:last-child .cart-product-name {
    border-bottom-width: 0px;
}



.card-panel .card-number {
  margin-bottom:15px;
}
@media (max-width: 575.98px) {
.card-panel .card-expiry.card-cvc > div {
    margin-bottom:15px;
}
}




.payment-item.row {
    margin-left:0px;
    margin-right:0px;
}
.payment-item:first-child > div {
    border:0px;
}
.payment-item > div {
    border-top: 2px solid #dee2e6;
    padding: 0.75rem;
}
@media (max-width: 767.98px) {
.payment-item > div:last-child {
        border-top:0px;
}
}
.payment-card .col_full.card-panel {
    margin-bottom: 20px!important;
}
.payment-card .logo {
    display:block;
    margin-left: auto;
    height: 35px;
    float: right;
}
.payment-card .logo img {
    height: 35px;
}
.payment-card .logo img:not(:last-child) {
    margin-right:10px;
}



.promo-codes {
     margin-bottom: 20px;
}
.promo-codes .applied-codes {
     margin-top: 20px;
}
.promo-codes .applied-codes span.code {
    font-weight: 600;
    color: #28a745
}
.promo-codes .applied-codes span.note {
     margin-left:10px;
}
@media (max-width: 767.98px) {
.promo-codes .button.add-promo{
        margin-top: 10px!important;
}
}



.payment .conditions {
  margin-top:30px;
  margin-bottom: 20px;
}
.payment .conditions .pretty {
  display:inline-block;
  width: 20px;
}
.button.payment {
  width: 100%;
}


